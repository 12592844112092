<template>
    <div>
        <div class="gl_title">编辑类型</div>
        <div class="gl_solid"></div>
        <div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="类型名称" prop="name">
                    <el-col :span="8">
                        <el-input placeholder="请输入类型名称" v-model="ruleForm.name"></el-input>
                    </el-col>
                </el-form-item>
                <!-- style="width: 104px;height: 108px;" -->
                <el-form-item label="车型图标" prop="logo">
                    <el-upload :disabled="ruleForm.logo ? true : false" class="avatar-uploader"
                        action="https://apisgu.chinaucv.com/staffPlatform/upload/uploadImage" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeaders"
                        accept="image/jpg,image/jpeg,image/png" name="image" :data="{ type: 0, vehicleModelId: 0 }"
                        :multiple="false">
                        <!-- <img v-if="ruleForm.logo" :src="ruleForm.logo" class="avatar"> -->
                        <div style="background: #fff;">
                            <el-image class="avatar" :zIndex="4" v-if="ruleForm.logo" :src="ruleForm.logo"
                                :preview-src-list="[ruleForm.logo]" fit="contain">
                            </el-image>
                        </div>
                        <i v-if="!ruleForm.logo" class="el-icon-plus avatar-uploader-icon"></i>
                        <div class="avatar_delete" v-if="ruleForm.logo" @click.stop="onClickDeleteImage()">
                            <img style="width:14px;height: 14px;" src="../../assets/error.png" alt="">
                        </div>
                    </el-upload>
                </el-form-item>

                <el-form-item label="价格设置">
                    <el-table :data="ruleForm.configList" style="width: 100%"
                        :header-cell-style="{ background: '#B5DFFF', color: '#333333' }">
                        <el-table-column label="销量区域">
                            <template slot-scope="scope">
                                <div style="height: 40px;line-height: 40px;margin-left: 10px;" v-if="scope.row.title">{{
                                    scope.row.title }}</div>
                                <div v-else @click="onClickCity(scope.$index, scope.row)" class="gl_view">
                                    <div style="margin-top: 8px;" v-show="scope.row.regionList.length == 0">请选择</div>
                                    <div v-show="scope.row.regionList.length > 0">
                                        <el-tag v-for="(tag, index) in scope.row.regionList" style="margin: 8px 8px 0 0 ;"
                                            @close="onCloseTag(index, scope.$index)" :key="tag.code" closable>
                                            {{ tag.province }}/{{ tag.city }}
                                        </el-tag>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="单价" width="200" align="center">
                            <template slot-scope=" scope">
                                <input type="number" placeholder="0.00" class="gl_input" v-if="scope.row.title"
                                    v-model="ruleForm.price" />
                                <input type="number" placeholder="0.00" class="gl_input" v-else v-model="scope.row.price" />

                                <!-- <el-input class="gl_input" type="number" placeholder="请输入区域金额" v-if="scope.row.title" v-model="ruleForm.price"></el-input> -->
                                <!-- <el-input class="" type="number" placeholder="请输入区域金额" v-else  v-model="scope.row.price"></el-input> -->
                            </template>

                        </el-table-column>
                        <el-table-column label="操作" width="100" align="center">
                            <template slot-scope="scope">
                                <el-button @click="onClickDelete(scope.$index)" v-if="!scope.row.title"
                                    type="text">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="width: 100%;height: 20px;"></div>
                    <span @click="onClickAddData" class="gl_button_add">单独设置销量区域</span>
                </el-form-item>

                <el-form-item label="排序" prop="sortId">
                    <el-col :span="6">
                        <el-input type="number" placeholder="请输入类型名称" v-model="ruleForm.sortId"></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-radio-group v-model="ruleForm.state">
                        <el-radio label="1">开放查询</el-radio>
                        <el-radio label="2">数据整理中</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                </el-form-item>
            </el-form>

            <el-dialog title="选择销量区域" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
                <div class="gl_play_div">
                    <el-tree ref="tree" :default-checked-keys="glActiveList" @check="handleCheckChange" :data="data"
                        show-checkbox node-key="code" :props="defaultProps">
                    </el-tree>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="onClickSuccess">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import codeJson from '../../plugins/city.json'
export default {
    data() {
        return {
            ruleForm: {
                name: '',
                logo: '',
                configList: [],
                sortId: 0,
                state: "1",
            },
            rules: {
                name: [
                    { required: true, message: '请输入类型名称', trigger: 'blur' },
                    { min: 0, max: 10, message: '长度在10个字符之内', trigger: 'blur' }
                ],
                logo: [
                    { required: true, message: '请上传车型图标', trigger: 'blur' },
                ],
                sortId: [
                    { required: true, message: '排序不能为空', trigger: 'blur' },
                ],
            },

            data: codeJson,
            defaultProps: {
                children: 'children',
                label: 'city'
            },
            dialogVisible: false,
            glActiveList: [],
            activeIndex: 0,
            regionList: [],
            uploadHeaders: {},

        }
    },
    created() {
        // 获取token
        let token = sessionStorage.getItem("token");
        this.uploadHeaders = { token };

        this.getElement();
    },
    mouted() {

    },
    methods: {
        async getElement() {
            const { data: res } = await this.$http({
                url: "/sales/getDetail",
                method: "POST",
                data: { id: this.$route.query.id - 0 },
            });
            res.content.state = res.content.state + ''
            if (res.content.configList.length == 0) {
                res.content.configList.push({
                    title: "默认全国所有城市（除单独设置）",
                })
            } else {
                res.content.configList.unshift({
                    title: "默认全国所有城市（除单独设置）",
                })
            }
            console.log(res.content)
            this.ruleForm = res.content
            // 
        },
        handleAvatarSuccess(file) {
            console.log(file.content.urls);
            this.ruleForm.logo = file.content.urls[0]
        },
        beforeAvatarUpload() {

        },
        // 删除tag
        onCloseTag(index, faIndex) {
            this.ruleForm.configList[faIndex].regionList.splice(index, 1)
        },
        onClickCity(index, item) {
            console.log(item)
            let glActiveList = [];
            this.regionList = item.regionList;

            //重置已经选择的
            let _data = JSON.parse(JSON.stringify(this.data))
            let ds = [];
            let ds1 = [];
            for (var c = 1; c < this.ruleForm.configList.length; c++) {
                if (index != c) {
                    for (var d = 0; d < this.ruleForm.configList[c].regionList.length; d++) {
                        let i = this.ruleForm.configList[c].regionList[d]
                        ds.push(i)
                    }
                }
            }
            // 找到全部选中项，根据code排序，小到大依次锁定
            let sortKeys = Object.keys(ds).sort((a, b) => {
                return ds[a].code - ds[b].code
            })
            // 循环排列好的 key, 重新组成一个新的数组
            for (var sortIndex in sortKeys) {
                ds1.push(ds[sortKeys[sortIndex]])
            }
            let dsNum = 0;
            for (var a = 0; a < _data.length; a++) {
                for (var b = 0; b < _data[a].children.length; b++) {
                    let item = _data[a].children[b];
                    if (ds.length > 0) {
                        // console.log(item.code, ds1[dsNum].code)
                        if (item.code == ds1[dsNum].code) {
                            item.disabled = true;
                            if (dsNum < ds1.length - 1) {
                                dsNum = dsNum + 1;
                                console.log(dsNum)
                            }
                        } else {
                            item.disabled = false;
                        }
                    } else {
                        item.disabled = false;
                    }
                }
            }
            this.data = []
            this.data = _data

            if (this.$refs.tree) {
                // 重置状态
                this.$refs.tree.setCheckedKeys([])
            }
            this.activeIndex = index;
            this.ruleForm.configList[index].regionList.forEach(item => {
                glActiveList.push(item.code)
            })
            this.glActiveList = glActiveList;
            setTimeout(() => {
                this.dialogVisible = true;
            })
        },
        handleClose() {
            this.dialogVisible = false;
        },
        handleCheckChange(data, checked) {
            let arr = [];
            checked.checkedNodes.forEach(item => {
                if (item.code > 100) {
                    arr.push(item)
                }
            })
            this.regionList = arr;
            //  
        },
        // 删除
        onClickDelete(index) {
            this.ruleForm.configList.splice(index, 1)
        },
        // 添加
        onClickAddData() {
            this.ruleForm.configList.push({
                price: "",
                regionList: [],
            })
        },
        onClickSuccess() {
            this.ruleForm.configList[this.activeIndex].regionList = this.regionList;
            this.dialogVisible = false;
        },
        // 确认正则
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
                    ruleForm.configList.shift();
                    let n = 0, m = 0;
                    ruleForm.configList.forEach((item) => {
                        if (!item.price) n = n + 1;
                        if (item.regionList.length == 0) m = m + 1;
                    })
                    if (m > 0) return this.$message.error("请选择单独设置的销量区域");
                    if (n > 0) return this.$message.error("请填写单独设置的单价");


                    this.$confirm('是否保存以上信息~', '提示', {
                        confirmButtonText: '保存',
                        cancelButtonText: '取消',
                    }).then(async () => {
                        console.log("123123")
                        const loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        const { data: res } = await this.$http({
                            url: "/sales/save",
                            method: "POST",
                            data: ruleForm,
                        });
                        loading.close();
                        if (res.resultStates != 0) return this.$message({
                            type: 'error',
                            message: res.message
                        });
                        this.$message({
                            type: 'success',
                            message: '保存成功!'
                        });
                        // this.$router.go(-1)
                    }).catch(() => { });


                }
            })
        },
        onClickDeleteImage() {
            this.ruleForm.logo = '';
        },
    },
}
</script>
<style scoped>
.gl_title {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
    opacity: 1;
}

.gl_solid {
    width: 100%;
    height: 1px;
    border-bottom: 2px solid #000000;
    opacity: 0.1;
    margin: 20px 0;
}

.avatar-uploader {
    width: 104px;
    height: 108px;
    position: relative;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 104px;
    height: 108px;
    line-height: 104px;
    text-align: center;
    border: 1px solid #CCCCCC;
    overflow: hidden;
}

.avatar {
    width: 104px;
    height: 108px;
    display: block;
}

.gl_view {
    /* width: 270px; */
    min-height: 36px;
    border: 1px solid #CCCCCC;
    opacity: 1;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0px 10px 8px;
    cursor: pointer;
    margin-left: 10px;
}

input {
    outline: none;
}

.gl_input {
    border: none;
    width: 140px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #333333;
    opacity: 1;
    text-align: center;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
}

.avatar_delete {
    position: absolute;
    top: -10px;
    right: 0;
}

.gl_button_add {
    width: 144px;
    height: 34px;
    background: rgba(0, 131, 227);
    border-radius: 4px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
    padding: 6px 20px;
    cursor: pointer;
}

.gl_play_div {
    overflow: scroll;
    height: 50vh;
}


input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #CCCCCC;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #CCCCCC;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #CCCCCC;

}
</style>